import React from 'react'
import Text from "../../ui-kit/text";
import Icon from "../../ui-kit/icon";
import {classNames} from "../../tools/react.dom.helpers";

const Errors = (props) => (
    <React.Fragment>
        { props.errors.map((item, key) => {
            return <Text  color={Text.COLOR.DANGER} size={Text.SIZE.SMALL} className={classNames("txt--error", (props.customClass ? props.customClass : '')  + " error")} key={key} icon={Icon.ICON.STATUS.ERROR} caption={item} />
        })}
    </React.Fragment>
);

export default Errors;