import _ from "underscore";
import moment from "moment";
import locale from "date-fns/locale/en-US";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn/index';

export const _t = function (str, data = {}) {
    if (typeof LANG !== 'undefined' && LANG && LANG[str]) {
        str = LANG[str];
    } else if (typeof LANG_EN !== 'undefined' && LANG_EN && LANG_EN[str]) {
        str = LANG_EN[str];
    } else {
        try {
            let untranslated = JSON.parse(localStorage.getItem('untranslated'));
            if (!untranslated) {
                untranslated = [];
            }

            if (str && str.length && !_.contains(untranslated, str)) {
                untranslated.push(str);
                localStorage.setItem('untranslated', JSON.stringify(untranslated));
            }
        } catch (e) {
            console.log('Need third-party allow cookies');
        }
    }
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            str = str.replace(new RegExp('%' + key + '%', 'g'), data[key]);
        }
    }
    return str;
}

export const _t_template = function (locale, str) {
    if (LANG_TEMPLATE[locale] && LANG_TEMPLATE[locale][str]) {
        str = LANG_TEMPLATE[locale][str]
    } else if (LANG_TEMPLATE['en'] && LANG_TEMPLATE['en'][str]) {
        str = LANG_TEMPLATE['en'][str]
    }

    return str;
}

moment.updateLocale(moment.locale(), {
    months: [_t('January'), _t('February'), _t('March'), _t('April'), _t('May'), _t('June'), _t('July'), _t('August'), _t('September'), _t('October'), _t('November'), _t('December')],
    monthsShort: [_t('Jan'), _t('Feb'), _t('Mar'), _t('Apr'), _t('May'), _t('Jun'), _t('Jul'), _t('Aug'), _t('Sep'), _t('Oct'), _t('Nov'), _t('Dec')],
    weekdays: [_t('Sunday'), _t('Monday'), _t('Tuesday'), _t('Wednesday'), _t('Thursday'), _t('Friday'), _t('Saturday')],
    weekdaysShort: [_t('Sun'), _t('Mon'), _t('Tue'), _t('Wed'), _t('Thu'), _t('Fri'), _t('Sat')],
    weekdaysMin: [_t('Su'), _t('Mo'), _t('Tu'), _t('We'), _t('Th'), _t('Fr'), _t('Sa')]
});

locale.localize.quarter = buildLocalizeFn({
    values: {
        narrow: [_t('1'), _t('2'), _t('3'), _t('4')],
        abbreviated: [_t('Q1'), _t('Q2'), _t('Q3'), _t('Q4')],
        wide: [_t('1st quarter'), _t('2nd quarter'), _t('3rd quarter'), _t('4th quarter')],
    },
    defaultWidth: 'wide',
    argumentCallback: quarter => quarter - 1
});
locale.localize.month = buildLocalizeFn({
    values: {
        narrow: [_t('J'), _t('F'), _t('M'), _t('A'), _t('M'), _t('J'), _t('J'), _t('A'), _t('S'), _t('O'), _t('N'), _t('D')],
        abbreviated: [_t('Jan'), _t('Feb'), _t('Mar'), _t('Apr'), _t('May'), _t('Jun'), _t('Jul'), _t('Aug'), _t('Sep'), _t('Oct'), _t('Nov'), _t('Dec')],
        wide: [_t('January'), _t('February'), _t('March'), _t('April'), _t('May'), _t('June'), _t('July'), _t('August'), _t('September'), _t('October'), _t('November'), _t('December')]
    },
    defaultWidth: 'wide',
});
locale.localize.day = buildLocalizeFn({
    values: {
        narrow: [_t('S'), _t('M'), _t('T'), _t('W'), _t('T'), _t('F'), _t('S')],
        short: [_t('Su'), _t('Mo'), _t('Tu'), _t('We'), _t('Th'), _t('Fr'), _t('Sa')],
        abbreviated: [_t('Sun'), _t('Mon'), _t('Tue'), _t('Wed'), _t('Thu'), _t('Fri'), _t('Sat')],
        wide: [_t('Sunday'), _t('Monday'), _t('Tuesday'), _t('Wednesday'), _t('Thursday'), _t('Friday'), _t('Saturday')]
    },
    defaultWidth: 'wide',
});