import React, {Component} from "react";
import {Visible} from "./visible";
import {_t} from '../../../js/translation';

export default class Hint extends Component{
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.clickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickHandler);
    }

    hideHint = () => {
        this.setState({
            isVisible: false
        });
    };

    clickHandler = (e) => {
        if (this.node && this.node.contains(e.target)) {
            this.setState({
                isVisible: !this.state.isVisible
            });
            return;
        }

        this.hideHint();
    };

    render() {
        return(
            <i type={'button'}  ref={node => this.node = node} className={'hint-component hint-component__icon fal fa-' + (this.props.icon || "info-square")} >
                <div className={'hint-component__block' + (this.state.isVisible ? ' hint-component__block-show' : '')}>
                    <Visible visible={this.props.title}>
                        <div className="hint-component__header">
                            <h5 className="hint-component__title">
                                {_t(this.props.title)}
                            </h5>
                        </div>
                    </Visible>

                    <div className="hint-component__body">
                        <div className="hint-component__content">
                            {_t(this.props.text)}
                        </div>
                    </div>
                </div>
            </i>
        )
    }
}