// constants for icons in commonly used actions
export default {
    BARS: "bars",
    MENU_ELLIPSIS: "ellipsis-vertical",
    MENU_DOTS: "ellipsis-vertical",
    MENU_BARS: "bars",
    DRAG_HANDLE: "grip-lines",
    SPINNER: "spinner",
    CHEVRON_DOWN: "chevron-down",
    UPLOAD: "upload",
    EXPAND: "chevron-down",
    TODAY: "calendar-day",
    CLOSE: "times",
    MOON: "moon-stars",
    YOUTUBE: 'youtube',
    INFO: 'info-circle',

    // actions
    EXPORT: "download", //"download",
    FILTER: "filter",
    FILTER_CLEAR: "times",
    HELP_GLOBAL: "life-ring",
    BACK: "arrow-left-long",
    DELETE: "trash-alt",
    CLEAR: "backspace",
    PRINT: "print",
    COPY: "copy",
    VIEW: "eye",
    EDIT: "pencil",
    BLOCK: "ban",
    UNBLOCK: "unlock", 
    DOWNLOAD: "download", //"download",
    ADD: "plus",
    MINUS: "minus",
    VISIBLE: "eye",
    INVISIBLE: "eye-slash",
    LOGOUT: "sign-out-alt",
    REFRESH: "sync-alt",
    PROLONG: "plus-square",
    CALENDAR: "calendar",
    CALENDAR_ADD: "calendar-plus",
    CALENDAR_CHECK: "calendar-check",
    COLUMNS: "columns",
    PIE_CHART: "chart-pie",
    TABLE: "table",
    CONFIG: "cog",
    SHARE: "share-alt",
    SETTINGS: "sliders-h",
    LOGS: "file-alt",
    COMMENT: "comment-alt-lines",
    TITLE: "comment",
    ID_CARD: "id-card",
    ADD_USER: "user-friends",
    USER_SLASH: "user-slash",
    DURATION: "hourglass-start",
    LINK: "link",
    LINK_EXTERNAL: "external-link",
    CHAIR: "chair",
    TIMEZONE: "globe",
    VIEW_REPORT: "file-chart-line",
    ROWS: "align-justify",
    USER: "user",
    USERS: "users",
    SQUARE_FULL: "square-full",
    PLAY: "play",
    EDIT_DATE: "calendar-lines-pen",
    PRIVATE_NOTE: "calendar-lines-pen",
    MEETING_ID: "list-ol",
    RESCHEDULE: "reply",

    // boolean
    SAVE: "check",
    ENABLE: "check",
    APPROVE: "check",
    CONFIRM: "check",
    ACCEPT: "check",
    ACTIVE: "check-square",
    YES: "check",
    CANCEL: "times",
    NO: "times",

    STATUS: {
        ACTIVE: "check",
        PENDING: "clock",
        CANCELED: "times",
        ERROR: "exclamation-triangle",
        BLOCKED: "ban",
        NEW: "plus",
    },

    INVOICE_DOWNLOAD: "file-download",
    REPORT: "line-columns", //"file-chart-line" ?
    POS: "store",
    REPORT_DOWNLOAD: 'download',

    ITEM: {
        SERVICE: "concierge-bell",
        PROVIDER: "id-card-alt", // "user-tie", //TODO provider vs client vs user?
        STATUS: "square-check",
        BOOKING: "calendar-check", //TODO
        CLIENT: "user",
        USER: "user-tie",
        INVOICE: "file-alt",
        NOTE: "sticky-note",
        GIFT_CARD: "gift-card",
        ONLINE_MEETING: "video-plus", // "video"? "camera-home"?

        DATE: "calendar",
        EMAIL: "envelope",
        SMS: "comment-alt",
        PHONE: "phone",
        TAX: "tags",
    },

    NAVIGATE: {
        BACK: "chevron-left",
        FORWARD: "chevron-right",
        PREV: 'arrow-left-long',
        NEXT: 'arrow-right-long',
    }
}