import React from "react";
import _ from "lodash";

let counter = 0;
export const generateId = () => 'generated_id_' + (++counter);

/**
 * @example classNames('class-1', isClass2 ? 'class-2' : null, 'class-3') => 'class-1 class-3' if isClass2 == false
 * @example classNames(['class-4', 'class-5']) => 'class-4 class-5'
 *
 * @param  {...string} classNames
 * @returns
 */
export const classNames = (...classNames) => {
    if (classNames.length && classNames[0] instanceof Array) {
        classNames = classNames[0];
    }
    return classNames.filter(Boolean).join(' ');
}

export const childrenArray = (children) =>
    !children ? [] :
    _.isArray(children) ? children.filter(Boolean) :
    [children];

export const childrenWithProps = (children, props) => React.Children.map(children, (child, idx) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.

    if (React.isValidElement(child)) {
        if (typeof (props) === 'function') {
            return React.cloneElement(child, props(child, idx));
        }
        return React.cloneElement(child, {...props});
    }
    return child;
});

